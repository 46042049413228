import React from "react";
import axios from "axios";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import Order from "./Order";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import loading from "./assets/loading.gif";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

import "./Orders.css";
import "font-awesome/css/font-awesome.min.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Orders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      orders: [],
      carriers: [],
      warehouseOptions: [],
      warehouse: this.getQueryParam("warehouse") || "all", // warehouse: { value: 'champion', label: 'Champion'},
      sort: this.getQueryParam("sort")?.split("/")?.[0] || "cust_ref",
      sortDirection: this.getQueryParam("sort")?.split("/")?.[1] || "desc",
      filter: this.getQueryParam("filter") || "active",
      saved: false,
      exported: false,
      isModalOpen: false,
      page:
        (this.getQueryParam("page") && parseInt(this.getQueryParam("page"))) ||
        1,
      searchInput: this.getQueryParam("search") || "",
      isAllocationMode: this.getQueryParam("allocation"),
    };
  }

  async fetchOrders() {
    this.setState({ loading: true });

    const orders = (
      await axios.get(
        `/api/orders${window.location.search}&page=${this.state.page}`
      )
    ).data;

    this.setState({ loading: false });

    return orders;
  }

  getWarehouseOption(id) {
    return this.state.warehouseOptions.find((option) => option.value === id);
  }

  async componentDidMount() {
    this.setDefaultParams();

    const warehouseOptions = (await axios.get("/api/warehouses")).data.map(
      (warehouse) => {
        return {
          value: warehouse.external_id,
          label: warehouse.name,
        };
      }
    );

    const orderStatusesOptions = (await axios.get("/api/order_statuses")).data;
    const carriers = (await axios.get("/api/carriers")).data;

    this.setState({ loading: true });

    this.setState({
      warehouseOptions,
      orderStatusesOptions,
      orders: await this.fetchOrders(),
      carriers,
    });
  }

  setDefaultParams() {
    if (!this.getQueryParam("warehouse"))
      this.insertQueryParam("warehouse", "all");
    if (!this.getQueryParam("sort"))
      this.insertQueryParam("sort", "cust_ref/desc");
    if (!this.getQueryParam("filter"))
      this.insertQueryParam("filter", "active");
    if (!this.getQueryParam("page")) this.insertQueryParam("page", "1");
  }

  insertQueryParam(key, value) {
    if (window.history.pushState) {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set(key, value);
      let newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        searchParams.toString();
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }
  deleteQueryParam(key) {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    let newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParams.toString();
    window.history.pushState({ path: newurl }, "", newurl);
  }
  getQueryParam(name, url = window.location.href) {
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  setWarehouse = async (selection) => {
    this.insertQueryParam("warehouse", selection.value);
    this.setState({
      warehouse: selection.value,
      orders: await this.fetchOrders(),
    });
  };

  selectPriority = (idx, priority) => {
    const orders = this.state.orders;
    orders[idx].priority = priority;
    orders[idx].dirty = true;

    this.setState({ orders });
  };

  selectWarehouse = async (idx, warehouseExternalId) => {
    await axios.post(
      `/api/orders/${this.state.orders[idx].cust_ref.replace(
        "#",
        ""
      )}/update_warehouse`,
      { warehouse_external_id: warehouseExternalId }
    );

    const orders = (
      await axios.get(
        `/api/orders${window.location.search}&page=${this.state.page}`
      )
    ).data;

    orders[idx].shiphawk_rate_id = null;
    orders[idx].rates = null;

    this.setState({ orders });
  };

  fetchRates = async (idx) => {
    const orders = this.state.orders;
    // orders[idx].dirty = true; not persisting anything besides rates refresh in backend

    const updatedRates = (
      await axios.post(
        `/api/orders/${orders[idx].cust_ref.replace("#", "")}/fetch_rates`,
        orders[idx]
      )
    ).data;
    orders[idx].rates = updatedRates;

    this.setState({ orders });
  };

  sendOrderToShiphawk = async (idx) => {
    const orders = this.state.orders;

    await axios.post(
      `/api/orders/${orders[idx].cust_ref.replace("#", "")}/shiphawk`
    );
  };

  saveBomOverrides = async (idx) => {
    try {
      await axios.post(
        `/api/orders/${this.state.orders[idx].cust_ref.replace(
          "#",
          ""
        )}/update_bom_override`,
        { boms: this.state.orders[idx].boms }
      );
    } catch (e) {
      toast.error(e.response.data.error);
    }

    const orders = (
      await axios.get(
        `/api/orders${window.location.search}&page=${this.state.page}`
      )
    ).data;

    orders[idx].shiphawk_rate_id = null;
    orders[idx].rates = null;

    this.setState({ orders });
  };

  selectCarrier = (idx, carrier) => {
    const orders = this.state.orders;
    orders[idx].carrier = carrier;
    orders[idx].dirty = true;

    this.setState({ orders });
  };

  selectRate = (idx, rate) => {
    const orders = this.state.orders;
    orders[idx].rate = rate;
    orders[idx].dirty = true;

    this.setState({ orders });
  };

  toggleSend = (idx, e) => {
    if (e.target.checked) {
      const orders = this.state.orders;
      orders[idx].order_status = this.state.orderStatusesOptions[14];
      orders[idx].dirty = true;

      this.setState({ orders });
    }
  };

  toggleFilter = async (filter) => {
    this.insertQueryParam("filter", filter);
    this.insertQueryParam("page", "1");

    await this.setState({
      page: 1,
      filter,
    });

    this.setState({
      orders: await this.fetchOrders(),
    });
  };

  toggleSort = async (newSort) => {
    let sort = this.state.sort;
    let sortDirection = this.state.sortDirection;

    if (newSort === sort) {
      if (sortDirection === "asc") {
        sortDirection = "desc";
      } else {
        sortDirection = "asc";
      }
    } else {
      sort = newSort;
      sortDirection = "desc";
    }

    this.insertQueryParam("sort", `${sort}/${sortDirection}`);

    this.setState({
      orders: await this.fetchOrders(),
      sort,
      sortDirection,
    });
  };

  selectOrderStatus = (idx, orderStatus) => {
    const orders = this.state.orders;
    orders[idx].order_status = orderStatus;
    orders[idx].dirty = true;

    this.setState({ orders });
  };

  updateDescription = (idx, e) => {
    const orders = this.state.orders;
    orders[idx].description = e.target.value;
    orders[idx].dirty = true;

    this.setState({ orders });
  };

  updateCombineWithOrderNumber = (idx, e) => {
    const orders = this.state.orders;
    orders[idx].combine_with = e.target.value;
    orders[idx].dirty = true;

    this.setState({ orders });
  };

  updateCustomerNotes = (idx, e) => {
    const orders = this.state.orders;
    orders[idx].customer_notes = e.target.value;
    orders[idx].dirty = true;

    this.setState({ orders });
  };

  ignoreOrder = async (idx, e) => {
    const orders = this.state.orders;
    orders.splice(idx, 1);

    this.insertQueryParam("allocation", orders.map((o) => o.id).toString());

    this.setState({
      orders: await this.fetchOrders(),
      isAllocationMode: true,
    });
  };

  moveOrderUp = async (idx, e) => {
    const orders = this.state.orders;
    swapElements(orders, idx - 1, idx);

    this.insertQueryParam("allocation", orders.map((o) => o.id).toString());

    this.setState({
      orders: await this.fetchOrders(),
      isAllocationMode: true,
    });
  };

  moveOrderDown = async (idx, e) => {
    const orders = this.state.orders;
    swapElements(orders, idx, idx + 1);

    this.insertQueryParam("allocation", orders.map((o) => o.id).toString());

    this.setState({
      orders: await this.fetchOrders(),
      isAllocationMode: true,
    });
  };

  moveOrderTop = async (idx, e) => {
    const orders = this.state.orders;

    const lastOrder = orders.pop();
    orders.unshift(lastOrder);

    this.insertQueryParam("allocation", orders.map((o) => o.id).toString());

    this.setState({
      orders: await this.fetchOrders(),
      isAllocationMode: true,
    });
  };

  moveOrderBottom = async (idx, e) => {
    const orders = this.state.orders;

    const [first, ...rest] = orders;
    const mutatedOrders = [...rest, first];

    this.insertQueryParam(
      "allocation",
      mutatedOrders.map((o) => o.id).toString()
    );

    this.setState({
      orders: await this.fetchOrders(),
      isAllocationMode: true,
    });
  };

  enterAllocationMode = async (idx, e) => {
    const orders = this.state.orders;

    this.insertQueryParam("allocation", orders.map((o) => o.id).toString());

    this.setState({
      orders: await this.fetchOrders(),
      isAllocationMode: true,
    });
  };

  exitAllocationMode = async (idx, e) => {
    this.deleteQueryParam("allocation");

    this.setState({
      orders: await this.fetchOrders(),
      isAllocationMode: false,
    });
  };

  saveOrders = async (confirmed) => {
    const dirtyOrders = this.state.orders
      .filter((order) => order.dirty || order.has_bom_override)
      .map((order) =>
        (({
          id,
          order_status,
          priority,
          description,
          combine_with,
          boms,
          has_bom_override,
          customer_notes,
          warehouse,
          carrier,
          rate,
        }) => ({
          id,
          order_status,
          priority,
          description,
          combine_with,
          boms,
          has_bom_override,
          customer_notes,
          warehouse,
          carrier,
          rate,
        }))(order)
      );

    if (
      !confirmed &&
      dirtyOrders.filter((order) => order.order_status.id === 6).length
    ) {
      this.setState({ isModalOpen: true });
      return;
    }

    try {
      await axios.put("/api/orders/bulk", { orders: dirtyOrders });
    } catch (e) {
      toast.error(e.message);
    }

    toast.success("Orders saved successfully!");
    this.setState({ saved: true, isModalOpen: false });

    // Reset dirty orders
    const orders = this.state.orders;
    orders.forEach((order) => {
      order.dirty = false;
      order.has_bom_override = false;
    });

    this.setState({ orders });

    setTimeout(() => this.setState({ saved: false }), 3000);
  };

  exportOrders = async () => {
    const resp = await axios.get(
      `/api/orders/export?filter=${this.state.filter}`,
      { responseType: "arraybuffer" }
    );

    this.setState({ exported: true });

    setTimeout(() => this.setState({ exported: false }), 3000);
  };

  searchOrder = async () => {
    this.insertQueryParam("search", this.state.searchInput);
    this.setState({
      orders: await this.fetchOrders(),
    });
  };

  changePage = async (next) => {
    await this.setState({ page: this.state.page + next });
    this.insertQueryParam("page", this.state.page);

    this.setState({ orders: await this.fetchOrders() });
  };

  render() {
    return (
      <div className="orders-container">
        <div className="orders-header">
          <div className="orders-bar">
            <Select
              classNamePrefix="orders-warehouse"
              options={this.state.warehouseOptions}
              onChange={this.setWarehouse}
              value={this.getWarehouseOption(this.state.warehouse)}
            />
            <div className="orders-search-bar">
              <InputGroup className="orders-search-input">
                <FormControl
                  placeholder="Search by Order #"
                  value={this.state.searchInput}
                  onChange={(e) =>
                    this.setState({ searchInput: e.target.value })
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      document.getElementById("button-addon2").click();
                    }
                  }}
                />
                <Button
                  variant="primary"
                  id="button-addon2"
                  onClick={this.searchOrder}
                >
                  Search
                </Button>
              </InputGroup>
            </div>
            <div className="orders-buttons">
              <Button variant="info" onClick={this.exportOrders}>
                {this.state.exported ? "Emailing!" : "Export"}
              </Button>
              <Button
                className="space-left"
                variant="success"
                onClick={() => this.saveOrders(false)}
              >
                {this.state.saved ? "Saved!" : "Save"}
              </Button>
            </div>
          </div>
          <div className="orders-filters-bar">
            <div className="form-check orders-check">
              Filters:
              <input
                type="radio"
                className="orders-filter-radio"
                name="style"
                onClick={() => this.toggleFilter("active")}
                defaultChecked={this.state.filter === "active"}
              />
              <label className="orders-filter-label">Active</label>
              <input
                type="radio"
                className="orders-filter-radio"
                name="style"
                onClick={() => this.toggleFilter("in_stock")}
                defaultChecked={this.state.filter === "in_stock"}
              />
              <label className="orders-filter-label">In Stock</label>
              <input
                type="radio"
                className="orders-filter-radio"
                name="style"
                onClick={() => this.toggleFilter("at_warehouse")}
                defaultChecked={this.state.filter === "at_warehouse"}
              />
              <label className="orders-filter-label">At Warehouse</label>
              <input
                type="radio"
                className="orders-filter-radio"
                name="style"
                onClick={() => this.toggleFilter("shipped")}
                defaultChecked={this.state.filter === "shipped"}
              />
              <label className="orders-filter-label">Shipped</label>
              <input
                type="radio"
                className="orders-filter-radio"
                name="style"
                onClick={() => this.toggleFilter("archived")}
                defaultChecked={this.state.filter === "archived"}
              />
              <label className="orders-filter-label">Archived</label>
              <input
                type="radio"
                className="orders-filter-radio"
                name="style"
                onClick={() => this.toggleFilter("all")}
                defaultChecked={this.state.filter === "all"}
              />
              <label className="orders-filter-label">All</label>
              <input
                type="radio"
                className="orders-filter-radio"
                name="style"
                onClick={() => this.toggleFilter("1w")}
                defaultChecked={this.state.filter === "1w"}
              />
              <label className="orders-filter-label">1W</label>
              <input
                type="radio"
                className="orders-filter-radio"
                name="style"
                onClick={() => this.toggleFilter("1m")}
                defaultChecked={this.state.filter === "1m"}
              />
              <label className="orders-filter-label">1M</label>
              <input
                type="radio"
                className="orders-filter-radio"
                name="style"
                onClick={() => this.toggleFilter("6m")}
                defaultChecked={this.state.filter === "6m"}
              />
              <label className="orders-filter-label">6M</label>
              <input
                type="radio"
                className="orders-filter-radio"
                name="style"
                onClick={() => this.toggleFilter("1y")}
                defaultChecked={this.state.filter === "1y"}
              />
              <label className="orders-filter-label">1Y</label>
            </div>
          </div>

          <div className="orders-buttons orders-pagination">
            {!this.state.isAllocationMode && (
              <div>
                {this.state.page > 1 && (
                  <div
                    className="orders-pagination-item"
                    onClick={() => this.changePage(-1)}
                  >
                    ⟵ Prev
                  </div>
                )}
                <div
                  className="orders-pagination-item"
                  onClick={() => this.changePage(1)}
                >
                  Next ⟶
                </div>
              </div>
            )}

            {!this.state.isAllocationMode && (
              <div
                className="orders-exit-allocation-mode"
                onClick={() => this.enterAllocationMode()}
              >
                Enter Allocation Mode
              </div>
            )}

            {this.state.isAllocationMode && (
              <div
                className="orders-exit-allocation-mode"
                onClick={() => this.exitAllocationMode()}
              >
                Exit Allocation Mode
              </div>
            )}
          </div>
        </div>

        {!this.state.loading && (
          <div className="orders-content">
            <Table
              striped
              bordered
              hover
              className={classNames(
                this.state.isAllocationMode ? "orders-allocation-mode" : "",
                "orders-table"
              )}
            >
              <thead className="orders-table-list-header">
                <tr>
                  {this.state.isAllocationMode && <th>Ignore</th>}
                  {this.state.isAllocationMode && <th>Move</th>}
                  {this.state.isAllocationMode && <th>Combine with</th>}
                  <th
                    className="orders-sortable-header"
                    onClick={() => this.toggleSort("cust_ref")}
                  >
                    Order #
                    {this.state.sort === "cust_ref" &&
                      this.state.sortDirection === "desc" && (
                        <i className="fa fa-sort-desc" aria-hidden="true"></i>
                      )}
                    {this.state.sort === "cust_ref" &&
                      this.state.sortDirection === "asc" && (
                        <i className="fa fa-sort-asc" aria-hidden="true"></i>
                      )}
                  </th>
                  <th>Order Date</th>
                  <th
                    className="orders-sortable-header"
                    onClick={() => this.toggleSort("order_status_id")}
                  >
                    Status
                    {this.state.sort === "order_status_id" &&
                      this.state.sortDirection === "desc" && (
                        <i className="fa fa-sort-desc" aria-hidden="true"></i>
                      )}
                    {this.state.sort === "order_status_id" &&
                      this.state.sortDirection === "asc" && (
                        <i className="fa fa-sort-asc" aria-hidden="true"></i>
                      )}
                  </th>
                  <th
                    className="orders-sortable-header"
                    onClick={() => this.toggleSort("priority")}
                  >
                    Priority
                    {this.state.sort === "priority" &&
                      this.state.sortDirection === "desc" && (
                        <i className="fa fa-sort-desc" aria-hidden="true"></i>
                      )}
                    {this.state.sort === "priority" &&
                      this.state.sortDirection === "asc" && (
                        <i className="fa fa-sort-asc" aria-hidden="true"></i>
                      )}
                  </th>
                  <th className="order-sku">Order Items</th>
                  <th className="order-sku">BOMs</th>
                  {/* <th className="order-sku">New Subs</th> */}
                  <th>Warehouse</th>
                  {/* <th>Winning Carrier</th> */}
                  {/* {!this.state.isAllocationMode && <th>Rates</th> */}

                  {!this.state.isAllocationMode && (
                    <th className="orders-configuration">Configuration</th>
                  )}

                  {!this.state.isAllocationMode && <th>Send to Shiphawk</th>}
                  {/* <th>Shipping Country</th> */}
                  {!this.state.isAllocationMode && <th>Warehouse Status</th>}
                  <th>Comments</th>
                  <th>Customer Notes</th>
                  <th>Last Updated</th>
                </tr>
              </thead>
              <tbody className="orders-table-list">
                {this.state.orders.map((order, idx) => {
                  return (
                    <Order
                      key={order.id}
                      idx={idx}
                      order={order}
                      orderStatusesOptions={this.state.orderStatusesOptions}
                      carriers={this.state.carriers}
                      isAllocationMode={this.state.isAllocationMode}
                      selectPriority={(idx, priority) =>
                        this.selectPriority(idx, priority)
                      }
                      selectWarehouse={(idx, warehouse) =>
                        this.selectWarehouse(idx, warehouse)
                      }
                      fetchRates={(idx) => this.fetchRates(idx)}
                      sendOrderToShiphawk={(idx) =>
                        this.sendOrderToShiphawk(idx)
                      }
                      selectCarrier={(idx, carrier) =>
                        this.selectCarrier(idx, carrier)
                      }
                      selectRate={(idx, rate) => this.selectRate(idx, rate)}
                      toggleSend={(idx, e) => this.toggleSend(idx, e)}
                      selectOrderStatus={(idx, orderStatus) =>
                        this.selectOrderStatus(idx, orderStatus)
                      }
                      updateDescription={(idx, e) =>
                        this.updateDescription(idx, e)
                      }
                      updateCombineWithOrderNumber={(idx, e) =>
                        this.updateCombineWithOrderNumber(idx, e)
                      }
                      updateCustomerNotes={(idx, e) =>
                        this.updateCustomerNotes(idx, e)
                      }
                      saveBomOverrides={(idx) => this.saveBomOverrides(idx)}
                      ignoreOrder={(idx, e) => this.ignoreOrder(idx, e)}
                      moveOrderUp={(idx, e) => this.moveOrderUp(idx, e)}
                      moveOrderDown={(idx, e) => this.moveOrderDown(idx, e)}
                      moveOrderTop={(idx, e) => this.moveOrderTop(idx, e)}
                      moveOrderBottom={(idx, e) => this.moveOrderBottom(idx, e)}
                    />
                  );
                })}
              </tbody>
            </Table>

            <div className="orders-buttons orders-pagination">
              {!this.state.isAllocationMode && this.state.page > 1 && (
                <div>
                  <div
                    className="orders-pagination-item"
                    onClick={() => this.changePage(-1)}
                  >
                    ⟵ Prev
                  </div>
                  <div
                    className="orders-pagination-item"
                    onClick={() => this.changePage(1)}
                  >
                    Next ⟶
                  </div>
                </div>
              )}

              {this.state.isAllocationMode && (
                <div
                  className="orders-exit-allocation-mode"
                  onClick={() => this.exitAllocationMode()}
                >
                  Exit Allocation Mode
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.loading && (
          <div className="loading-container">
            <div className="loading-title">Loading...</div>
            <img className="loading" src={loading} alt="" />
          </div>
        )}

        {this.state.isModalOpen && (
          <div className="popup" id="popup-dialog">
            <div className="popup-content" id="content">
              <div className="popup-content-container">
                Warning: Attempting to save an <b>overridden</b> order. Are you
                sure you wish to proceed?
                <div className="popup-button-group">
                  <Button
                    variant="primary"
                    onClick={() => this.setState({ isModalOpen: false })}
                  >
                    No
                  </Button>
                  <Button
                    className="popup-button"
                    variant="success"
                    onClick={() => this.saveOrders(true)}
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        <ToastContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

const swapElements = (array, index1, index2) => {
  [array[index1], array[index2]] = [array[index2], array[index1]];
};

export default Orders;
