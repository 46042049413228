import React from 'react';

import './WarehouseEvents.css'
import Button from 'react-bootstrap/Button';
import axios from 'axios';

class WarehouseEvents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  // TODO: why doesn't s3 presigned links work?
  exportFile = async (selection) => {
    const resp = await axios.get(`/api/warehouse_events/export?warehouse=${selection.warehouse.toLowerCase()}`, { responseType: 'arraybuffer' });

    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `warehouse_events_${selection.warehouse.toLowerCase()}.${selection.format}`);
    document.body.appendChild(link);
    link.click();
  }

  render() {
    return (
      <div className="warehouse-events-container">
        <div className="warehouse-events-title">Warehouse Events</div>

        <div className="warehouse-events-section">
          <span className="warehouse-events-subtitle">3PL</span>
          <Button className="warehouse-events-button" variant="info" onClick={() => this.exportFile({warehouse: '3pl', format: 'csv'})}>Export</Button>
        </div>

        <div className="warehouse-events-section">
          <span className="warehouse-events-subtitle">Champion</span>
          <Button className="warehouse-events-button" variant="info" onClick={() => this.exportFile({warehouse: 'champion', format: 'csv'})}>Export</Button>
        </div>

        <div className="warehouse-events-section">
          <span className="warehouse-events-subtitle">3PL Links</span>
          <Button className="warehouse-events-button" variant="info" onClick={() => this.exportFile({warehouse: '3pllinks', format: 'csv'})}>Export</Button>
        </div>

      </div>
    );
  }
}

export default WarehouseEvents;
