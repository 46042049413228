import React from 'react';

import './Warehouse.css'

class WarehouseHeader extends React.Component {
  render() {
    return (
      <div className="warehouse-header">
        <div className="warehouse-header-item" onClick={this.props.selectStage}>
          <div className="warehouse-header-item-title">
            Stage&nbsp;<b>{this.props.stage}</b>: 
          </div>
          <div className="warehouse-header-item-name"><b>{this.props.stageName}</b></div>
        </div>
        <div className="warehouse-header-item" onClick={this.props.changeName}>
          <div className="warehouse-header-item-title">
            User:
          </div>
          <div className="warehouse-header-item-name"><b>{this.props.name}</b></div>
        </div>
      </div>
    );
  }
}

export default WarehouseHeader;
