import React from 'react';

import Uploader from './Uploader';
import './Subs.css'
import Button from 'react-bootstrap/Button';
import axios from 'axios';

class Subs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      isUploaded: false
    }
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  }

  closeModal = () => {
    this.setState({ isModalOpen: false });
  }

  // TODO: why doesn't s3 presigned links work?
  exportFile = async (selection) => {
    const resp = await axios.get('/api/substitution_rules/export', { responseType: 'arraybuffer' });

    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'substitutions.xlsx');
    document.body.appendChild(link);
    link.click();
  }

  // TODO: DRY with Inventory
  render() {
    return (
      <div className="subs-container">
        <div className="subs-title">Substitutions</div>
        <div className="subs-section">
          <Button className="subs-button" variant="info" onClick={this.exportFile}>Export</Button>
          <Button variant="primary" onClick={this.openModal}>Import</Button>
        </div>

        { this.state.isModalOpen &&
          <div className="popup" id="popup-dialog">
            <div className="popup-content" id="content">
              <div className="popup-content-header">
                <Button variant="danger" onClick={this.closeModal}>X</Button>
              </div>
              <div className="popup-content-container">
                <Uploader uploadEndpoint="/api/substitution_rules/import" format="xlsx" placeholder="Import Subs .xlsx file"/>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Subs;
