import { BrowserRouter as Router } from "react-router-dom";
import Route from "./Route";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      <Route />
    </Router>
  );
}

export default App;
