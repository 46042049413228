import React from "react";

import Uploader from "./Uploader";
import "./Inventory.css";
import Button from "react-bootstrap/Button";
import axios from "axios";

class Inventory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      warehouse: null,
      modalMode: null,
      isUploaded: false,
    };
  }

  openModal = (selection) => {
    this.setState({
      warehouse: selection.warehouse,
      format: selection.format,
      endpoint: `/api/components/import_inventory_${selection.warehouse.toLowerCase()}`,
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  // TODO: why doesn't s3 presigned links work?
  exportFile = async (selection) => {
    const resp = await axios.get(
      `/api/components/export_inventory_${selection.warehouse.toLowerCase()}`,
      { responseType: "arraybuffer" }
    );

    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `inventory_${selection.warehouse.toLowerCase()}.${selection.format}`
    );
    document.body.appendChild(link);
    link.click();
  };

  render() {
    return (
      <div className="inventory-container">
        <div className="inventory-title">Inventory</div>
        <div className="inventory-section">
          <span className="inventory-subtitle">Champion</span>
          <Button
            className="inventory-button"
            variant="info"
            onClick={() =>
              this.exportFile({ warehouse: "Champion", format: "xlsx" })
            }
          >
            Export
          </Button>
          <Button
            className="inventory-button"
            variant="primary"
            onClick={() =>
              this.openModal({ warehouse: "Champion", format: "xlsx" })
            }
          >
            Import
          </Button>
        </div>

        <div className="inventory-section">
          <span className="inventory-subtitle">Tighe</span>
          <Button
            className="inventory-button"
            variant="info"
            onClick={() =>
              this.exportFile({ warehouse: "Tighe", format: "xlsx" })
            }
          >
            Export
          </Button>
          <Button
            className="inventory-button"
            variant="primary"
            onClick={() =>
              this.openModal({ warehouse: "Tighe", format: "xlsx" })
            }
          >
            Import
          </Button>
        </div>

        <div className="inventory-section">
          <span className="inventory-subtitle">3PL Links</span>
          <Button
            className="inventory-button"
            variant="info"
            onClick={() =>
              this.exportFile({ warehouse: "3PLLinks", format: "xlsx" })
            }
          >
            Export
          </Button>
          <Button
            className="inventory-button"
            variant="primary"
            onClick={() =>
              this.openModal({ warehouse: "3PLLinks", format: "xlsx" })
            }
          >
            Import
          </Button>
        </div>

        {this.state.isModalOpen && (
          <div className="popup" id="popup-dialog">
            <div className="popup-content" id="content">
              <div className="popup-content-header">
                <Button variant="danger" onClick={this.closeModal}>
                  X
                </Button>
              </div>
              <div className="popup-content-container">
                <Uploader
                  uploadEndpoint={this.state.endpoint}
                  format={this.state.format}
                  placeholder={`Import .${this.state.format} file for ${this.state.warehouse}`}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Inventory;
