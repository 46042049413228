import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import Table from 'react-bootstrap/Table';
import Order from './Order';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import loading from './assets/loading.gif';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';

import './Tasks.css'
import 'font-awesome/css/font-awesome.min.css';
import "react-datepicker/dist/react-datepicker.css";

class Tasks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      tasks: [],
      orderNumber: '',
      message: '',
    };
  }

  async fetchTasks() {
    this.setState({ loading: true });

    const tasks = (await axios.get('/api/tasks')).data

    this.setState({ loading: false });

    return tasks;
  }

  getWarehouseOption(id) {
    return this.state.warehouseOptions.find(option => option.value === id);
  }

  async componentDidMount() {
    this.setState({ loading: true });

    this.setState({ tasks: await this.fetchTasks() });
  }

  onSendEmail = () => {
    if (this.state.orderNumber) {
      this.setState({sendEmail: !this.state.sendEmail})
    }
  }

  createTask = async (sendEmail = false) => {
    const tasks = (await axios.post('/api/tasks', {
      order_number: this.state.orderNumber,
      message: this.state.message,
    })).data

    this.setState({ tasks });
  }

  completeTask = async (id) => {
    const tasks = (await axios.post('/api/tasks/complete', {
      task_id: id,
    })).data

    this.setState({ tasks });
  }

  render() {
    return (
      <div className="tasks-container">
        <p>Tasks can be seen and completed by all users in the Admin app.</p>
        <p>Tasks can also be created through automated processes.</p>
        <p>The same task can be created for multiple orders at once, with one order number on each line.</p>

        <div className="tasks-bar">
          <InputGroup>
            <div className="tasks-field">
              <label className="tasks-field-label">Order # (Optional)</label>
              <FormControl
                as="textarea"
                className="tasks-field-order"
                onChange={(e) => this.setState({orderNumber: e.target.value})}
              />
            </div>
            <div className="tasks-field">
              <label className="tasks-field-label">Message</label>
              <FormControl
                as="textarea"
                className="tasks-field-message"
                onChange={(e) => this.setState({message: e.target.value})}
              />
            </div>
          </InputGroup>

          <div className="tasks-buttons">
            <Button variant="success" className="tasks-field-button" onClick={this.createTask}>+ Create Task</Button>
          </div>
        </div>

        { !this.state.loading &&
          <div className="vertical">
            <Table striped bordered hover className="tasks-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Order #</th>
                  <th>Message</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                { this.state.tasks.map((task, idx) => {
                  return (
                    <tr>
                      <td className="tasks-complete-cell"><Button variant="success" onClick={() => this.completeTask(task.id)}>Complete</Button></td>
                      <td>{task.order?.cust_ref}</td>
                      <td>{task.message}</td>
                      <td>{moment.utc(task.created_at).tz("America/New_York").format('MM/DD/YY H:mm')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        }

        { this.state.isModalOpen &&
          <div className="popup" id="popup-dialog">
            <div className="popup-content" id="content">
              <div className="popup-content-container" >
                Warning: You will be sending emails to {this.state.orderNumber.split('\n').length} order(s). Are you sure you wish to proceed?

                <div className="popup-button-group">
                  <Button variant="danger" onClick={() => this.setState({isModalOpen: false})}>No</Button>
                  <Button className="popup-button" variant="success" onClick={() => this.createTask(true)}>Yes</Button>
                </div>
              </div>
            </div>
          </div>
        }

        { this.state.loading &&
          <div className="loading-container">
            <div className="loading-title">Loading...</div>
            <img className="loading" src={loading} alt=""/>
          </div>
        }
      </div>
    );
  }
}

function getNextDay(date = new Date()) {
  const nextDay = new Date(date.getTime());
  nextDay.setDate(date.getDate() + 1);

  return nextDay;
}

export default Tasks;
