import React, { useState } from 'react';
import Webcam from "react-webcam";
import Button from 'react-bootstrap/Button';
import { isMobile } from 'react-device-detect';

import './Warehouse.css'

export default function(props) {
  const [image, setImage] = useState('');
  const webcamRef = React.useRef(null);
  
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc)
  });

  const videoConstraints = {
    width: 320,
    height: 320,
  }
  if (isMobile) videoConstraints.facingMode = 'environment';

  return (
    <div className="warehouse-webcam-container">
      <div className="warehouse-webcam-img">
        { image == '' ?
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          /> : 
          <img src={image} />
        }
      </div>
      <div>
        {image != '' ?
          <div>
            <Button onClick={(e) => { setImage('') }} variant="primary">
              Retake Image
            </Button>

            { props.multiple &&
              <Button className="space-left" onClick={(e) => { props.saveImage(image, true); setImage('') }} variant="primary">
                Save & Take Another
              </Button>
            }

            <Button className="space-left" onClick={(e) => { props.saveImage(image, false) }} variant="primary">
              Save & Finish
            </Button>
          </div> :
          <Button onClick={(e) => { capture() }} variant="primary">
            Capture
          </Button>
        }
      </div>
    </div>
  );
};
