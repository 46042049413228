import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import axios from "axios";
import env from "react-dotenv";
import { getCookie } from "./helpers";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import "bootstrap/dist/css/bootstrap.min.css";

Bugsnag.start({
  apiKey: "d80bec9c094bbe977203eb727291ea9b",
  plugins: [new BugsnagPluginReact()],
});

axios.defaults.baseURL = env.REACT_APP_API_ROOT;
axios.defaults.headers.common["Authorization"] = getCookie("ez_t");
axios.defaults.headers.common["Timestamp"] = "1694100525";

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);
