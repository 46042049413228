import React from 'react';

import './DraftOrder.css'
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import {
  humanizeSide,
  humanizeSides,
  getProRinkNameFromSku,
  getNearestRinkSizeFromWidth,
  generateRinkImageStyle,
  generateImageUrlFromNormalizedSku,
  isOriginalRinkSku,
  getWupUpgradeNameFromSkus,
  getOrgRinkWidth,getOrgRinkLength,getOrgRinkStyles,getOrgRinkFeatures,
  getProRinkWidth,getProRinkLength,getProRinkStyles,getProRinkFeatures,
} from '@ezicerinks/js-utilities'

class DraftOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rinkSku: '',
      upgradeRinkSku: '',
      email: '',
      discountPercentage: '',
      draftOrderUrl: null,
      draftOrderText: null,
      loading: false,
    }
  }

  generateDraftOrder = async () => {
    this.setState({loading: true});

    let upgrade = null;
    if (this.state.upgradeRinkSku) {
      const oldRink = this.state.rinkSku;
      const newRink = this.state.upgradeRinkSku;
      const isOriginalUpgrade = isOriginalRinkSku(oldRink);

      const oldWidth = isOriginalUpgrade ? getOrgRinkWidth(oldRink) : getProRinkWidth(oldRink)
      const oldLength = isOriginalUpgrade ? getOrgRinkLength(oldRink) : getProRinkLength(oldRink)
      const oldStyle = isOriginalUpgrade ? getOrgRinkStyles(oldRink) : getProRinkStyles(oldRink)
      const oldFeatures = isOriginalUpgrade ? getOrgRinkFeatures(oldRink) : getProRinkFeatures(oldRink)

      const newWidth = getProRinkWidth(newRink)
      const newLength = getProRinkLength(newRink)
      const newStyle = getProRinkStyles(newRink)
      const newFeatures = getProRinkFeatures(newRink)

      const description = getWupUpgradeNameFromSkus(oldRink, newRink)

      upgrade = (await axios.post(`/api/upgrades/request_quote`, {
        is_original_upgrade: isOriginalUpgrade,
        old_width: oldWidth,
        old_length: oldLength,
        old_style: oldStyle,
        old_features: oldFeatures,

        new_width: newWidth,
        new_length: newLength,
        new_style: newStyle,
        new_features: newFeatures,
      })).data;

      const savedQuote = (await axios.post(`/api/upgrades/save_quote`, {
        boms: upgrade.boms,
        upgrade_price: upgrade.upgrade_price,
        liner: upgrade.liner,
        rink_image_url: generateImageUrlFromNormalizedSku(this.state.upgradeRinkSku),
        description
      })).data;

      const draftOrder = (await axios.post('/api/shopify/admin_draft_order', {
        rink_shopify_id: null,
        upgrade_wup_shopify_id: savedQuote.upgrade_shopify_variant_id,
        upgrade_liner_shopify_id: savedQuote.liner_shopify_variant_id,
        email: this.state.email,
        discount_percentage: this.state.discountPercentage,
      })).data

      this.setState({loading: false, draftOrderText: `${description} \n\n${draftOrder.draft_order.draft_order.invoice_url}`, draftOrderUrl: draftOrder.draft_order.draft_order.invoice_url})
    } else {
      const savedVariant = (await axios.post(`/api/shopify/variant`, {
        sku: this.state.rinkSku,
        description: getProRinkNameFromSku(this.state.rinkSku),
        image_url: generateImageUrlFromNormalizedSku(this.state.rinkSku),
      })).data;

      const draftOrder = (await axios.post('/api/shopify/admin_draft_order', {
        rink_shopify_id: savedVariant.shopify_variant_id,
        upgrade_wup_shopify_id: null,
        upgrade_liner_shopify_id: null,
        email: this.state.email,
        discount_percentage: this.state.discountPercentage,
      })).data

      this.setState({loading: false, draftOrderText: `${ getProRinkNameFromSku(this.state.rinkSku)} \n\n${draftOrder.draft_order.draft_order.invoice_url}`, draftOrderUrl: draftOrder.draft_order.draft_order.invoice_url})
    }
  }

  render() {
    return (
      <div className="draftorder-container">
        <div className="draftorder-title">Draft Order Generator</div>

        <div className="draftorder-form">

          <div className="draftorder-section">
            <div className="draftorder-label">
              Rink SKU*
            </div>
            <input type="text" className="form-control draftorder-input" onChange={(e) => this.setState({rinkSku: e.target.value})}/>
          </div>

          <div className="draftorder-section">
            <div className="draftorder-label">
              Upgrade Rink SKU
            </div>
            <input type="text" className="form-control draftorder-input" onChange={(e) => this.setState({upgradeRinkSku: e.target.value})}/>
          </div>

          <div className="draftorder-section">
            <div className="draftorder-label">
              Customer Email
            </div>
            <input type="text" className="form-control draftorder-input" onChange={(e) => this.setState({email: e.target.value})}/>
          </div>

          <div className="draftorder-section">
            <div className="draftorder-label">
              Percentage Discount
            </div>
            <input type="text" className="form-control draftorder-input" onChange={(e) => this.setState({discountPercentage: e.target.value})}/>
          </div>

          <Button className="draftorder-button" variant="primary" onClick={this.generateDraftOrder}>{this.state.loading ? 'Generating...' : 'Generate'}</Button>
        </div>

        { this.state.draftOrderUrl &&
          <textarea
            className="form-control draftorder-textarea"
            rows="6"
            value={ this.state.draftOrderText }
            onChange={(e) => this.setState({draftOrderText: e.target.value})}
          />
        }
      </div>
    );
  }
}

export default DraftOrder;
