import React from "react";

import "./Reports.css";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Uploader from "./Uploader";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      isUploaded: false,
    };
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  exportOrderData = async () => {
    axios.get(`/api/reports/yearly_order_export`);
    
    toast.success("Starting export. Check the most recent yearly spreadsheet in the Google Drive DATASET folder in a few minutes.");
  };

  exportRates = async () => {
    const resp = await axios.get("/api/shiphawk/export_bulk_order_rating", {
      responseType: "arraybuffer",
    });

    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `bulk_order_rating_${new Date().toISOString()}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
  };

  exportBomsNoSubs = async () => {
    const resp = await axios.get("/api/reports/boms_no_subs", {
      responseType: "arraybuffer",
    });

    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `boms_no_subs_${new Date().toISOString()}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  exportWups = async () => {
    const resp = await axios.get("/api/reports/wups", {
      responseType: "arraybuffer",
    });

    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `wups_${new Date().toISOString()}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  exportTouchpoints = async () => {
    const resp = await axios.get("/api/reports/touchpoints", {
      responseType: "arraybuffer",
    });

    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `touchpoints_${new Date().toISOString()}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  openModal = (selection) => {
    this.setState({
      format: selection.format,
      endpoint: `/api/shiphawk/import_bulk_order_rating`,
      isModalOpen: true,
    });
  };

  render() {
    return (
      <div className="reports-container">
        <div className="reports-title">Reports</div>

        <div className="reports-section">
          <div className="reports-subtitle">Update Admin Database Order Export</div>
          <Button
            className="reports-button"
            variant="info"
            onClick={this.exportOrderData}
          >
            Update
          </Button>
        </div>

        <div className="reports-section">
          <div className="reports-subtitle">Shiphawk Bulk Order Rating</div>
          <Button
            className="reports-button"
            variant="info"
            onClick={this.exportRates}
          >
            Export Last Completed Result
          </Button>

          <Button
            className="reports-button"
            variant="primary"
            onClick={() => this.openModal({ format: "xlsx" })}
          >
            Import (Receive through Email)
          </Button>
        </div>

        <div className="reports-section">
          <div className="reports-subtitle">Touchpoints</div>
          <Button
            className="reports-button"
            variant="info"
            onClick={this.exportTouchpoints}
          >
            Export
          </Button>
        </div>

        <div className="reports-section">
          <div className="reports-subtitle">Order BOMs (No Subs)</div>
          <Button
            className="reports-button"
            variant="info"
            onClick={this.exportBomsNoSubs}
          >
            Export
          </Button>
        </div>

        <div className="reports-section">
          <div className="reports-subtitle">WUPs</div>
          <Button
            className="reports-button"
            variant="info"
            onClick={this.exportWups}
          >
            Export
          </Button>
        </div>

        {this.state.isModalOpen && (
          <div className="popup" id="popup-dialog">
            <div className="popup-content" id="content">
              <div className="popup-content-header">
                <Button variant="danger" onClick={this.closeModal}>
                  X
                </Button>
              </div>
              <div className="popup-content-container">
                <Uploader
                  uploadEndpoint={this.state.endpoint}
                  format={this.state.format}
                  placeholder={`Import .${this.state.format} file for Shiphawk`}
                />
              </div>
            </div>
          </div>
        )}

        <ToastContainer
          position="top-center"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

export default Reports;
