import React from 'react';
import axios from 'axios';
import GoogleLogin from 'react-google-login';
import { setCookie } from './helpers';
import './Signin.css';

class Signin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      errorMessage: ''
    };
  }

  onGoogleLogin = async (googleResponse) => {
    this.setState({ errorMessage: '' });

    try {
      const loginResponse = await axios.post('/api/signin', { googleResponse })
      this.processSuccessfulSigninActions(loginResponse)
    } catch (e) {
      if (e.response.status === 404) {
        this.setState({ errorMessage: 'Facebook user is not signed up. Please create an account first.' });
      } else {
        this.setState({ errorMessage: 'An error has occurred. Please try your request again later.' });
      }
    }
  }

  processSuccessfulSigninActions(response) {
    const token = response.headers.authorization;

    setCookie('ez_t', token, 7);

    window.location = '/supplychain';
  }

  render() {
    return (
      <div className="signin-container">
        <div className="signin-title">Welcome to EZ ICE Admin!</div>
        <GoogleLogin
          clientId="57257109537-27scm52tl5qjpki4nbis4emoitvh00i9.apps.googleusercontent.com"
          buttonText="Sign In"
          onSuccess={this.onGoogleLogin}
          onFailure={this.onGoogleLogin}
          cookiePolicy={'single_host_origin'}
        />
      </div>
    );
  }
}

export default Signin;
