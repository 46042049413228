import React from 'react';
import axios from 'axios';
import './Upload.css';

class Uploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploaded: false,
    };
  }

  onFileUpload = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    await axios({
      method: 'post',
      url: this.props.uploadEndpoint,
      data: formData,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
    })

    this.setState({ uploaded: true });
  }

  render() {
    return (
      <div className="upload-container">
      { this.state.uploaded &&
          <div className="upload-done">Uploaded!</div>
        }
        <div className="upload-content">
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              multiple={false}
              accept={`.${this.props.format}`}
              onChange={this.onFileUpload}
            />
            <label className="custom-file-label">{this.props.placeholder}</label>
          </div>
        </div>
      </div>
    );
  }
}

export default Uploader;
