import React from "react";

import "./Scripts.css";
import Button from "react-bootstrap/Button";
import axios from "axios";
import FormControl from "react-bootstrap/FormControl";

class Scripts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderNumbers: "",
      resetShiphawkOrdersCompleted: false,
    };
  }

  addWupToBidSheet = async () => {
    try {
      await axios.post("/api/scripts/reset_shiphawk_orders", {
        order_numbers: this.state.orderNumbers,
      });

      this.setState({ resetShiphawkOrdersCompleted: true });
    } catch (e) {
      // TODO: add error state
    }
  };

  render() {
    return (
      <div className="scripts-container">
        <div className="scripts-subtitle">Reset Shiphawk Orders</div>

        <div className="scripts-section">
          <label>(One order number per line)</label>
          <FormControl
            as="textarea"
            rows={3}
            className="announcements-field-order"
            onChange={(e) => this.setState({ orderNumbers: e.target.value })}
          />
          <Button
            className="scripts-button"
            variant="info"
            onClick={this.addWupToBidSheet}
          >
            {this.state.resetShiphawkOrdersCompleted ? "Done!" : "Run"}
          </Button>
        </div>
      </div>
    );
  }
}

export default Scripts;
