import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Signin from "./Signin";
import Orders from "./Orders";
import Inventory from "./Inventory";
import Subs from "./Subs";
import WarehouseEvents from "./WarehouseEvents";
import Upgrades from "./Upgrades";
import Announcements from "./Announcements";
import DraftOrder from "./DraftOrder";
import Warehouse from "./Warehouse";
import Scripts from "./Scripts";
import Tasks from "./Tasks";
import Reports from "./Reports";
import NavBar from "./NavBar";

// WarehouseEvents == /warehouse
const navBarRoutes = [
  "/supplychain",
  "/inventory",
  "/subs",
  "/warehouse",
  "/upgrades",
  "/announcements",
  "/draftorder",
  "/reports",
  "/scripts",
  "/tasks",
];

const Routes = () => {
  return (
    <div className="app">
      {navBarRoutes.includes(useLocation().pathname) && <NavBar />}
      <div className="app-container">
        <Switch>
          <Route path="/signin" component={Signin} />
          <Route path="/supplychain" component={Orders} />
          <Route path="/inventory" component={Inventory} />
          <Route path="/subs" component={Subs} />
          <Route path="/warehouse" component={WarehouseEvents} />
          <Route path="/upgrades" component={Upgrades} />
          <Route path="/announcements" component={Announcements} />
          <Route path="/draftorder" component={DraftOrder} />
          <Route path="/scripts" component={Scripts} />
          <Route path="/reports" component={Reports} />
          <Route path="/tasks" component={Tasks} />

          <Route path="/3pl" component={Warehouse} />
          <Route path="/champion" component={Warehouse} />
          <Route path="/3pllinks" component={Warehouse} />

          <Route exact path="/">
            <Redirect to="/supplychain" />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Routes;
