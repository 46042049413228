import React from "react";
import axios from "axios";
import logo from "./assets/ezice.png";
import "./NavBar.css";
import { Redirect } from "react-router-dom";

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      shouldRedirect: false,
      taskCount: 0,
    };
  }

  async componentDidMount() {
    try {
      const currentUser = (await axios.get("/api/users/current")).data;

      this.setState({ currentUser });
    } catch (e) {
      if (e.response.status === 401) {
        this.setState({ shouldRedirect: true });
      } else {
        console.error(e);
      }
    }
  }

  handleSignout = async () => {
    await axios.delete("/api/signout/");

    deleteCookie("ez_t");

    window.location = "/";
  };

  render() {
    if (this.state.shouldRedirect) {
      return <Redirect to="/signin" />;
    } else {
      return (
        <div className="navigation-bar">
          <a href="/">
            <img className="navigation-logo" src={logo} alt="" />
          </a>
          <div className="navigation-links">
            <div className="navigation-item navigation-item-supply-chain">
              <a href="/inventory">Inventory</a>
            </div>
            <div className="navigation-item navigation-item-supply-chain">
              <a href="/subs">Subs</a>
            </div>
            <div className="navigation-item navigation-item-supply-chain">
              <a href="/warehouse">Warehouse</a>
            </div>
            <div className="navigation-item navigation-item-supply-chain">
              <a href="/upgrades">Upgrades</a>
            </div>
            <div className="navigation-item navigation-item-supply-chain">
              <a href="/scripts">Scripts</a>
            </div>
            <div className="navigation-item navigation-item-supply-chain">
              <a href="/reports">Reports</a>
            </div>
            <div className="navigation-item navigation-item-customer-success">
              <a href="/announcements">Announcements</a>
            </div>
            <div className="navigation-item navigation-item-customer-success">
              <a href="/draftorder">Draft Order</a>
            </div>
            <div className="navigation-item">
              <a href="/tasks">Tasks</a>
            </div>
            <div
              className="navigation-item navigation-signout"
              onClick={this.handleSignout}
            >
              Sign Out
            </div>
          </div>
        </div>
      );
    }
  }
}

function deleteCookie(name) {
  setCookie(name, "", -1);
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export default NavBar;
