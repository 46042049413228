import React, { useState, useEffect } from 'react';

import {
  BrowserBarcodeReader,
  NotFoundException,
  ChecksumException,
  FormatException
} from '@zxing/library';

function Scanner(props) {
  const [code, setCode] = useState('');

  const codeReader = new BrowserBarcodeReader();

  console.log("ZXing code reader initialized");

  useEffect(() => {
    codeReader.getVideoInputDevices().then(videoInputDevices => {
      const deviceId = videoInputDevices[videoInputDevices.length - 1].deviceId;

      console.log(`Starting decode from camera with id ${deviceId}`);
      decodeContinuously(deviceId);
    })

    return () => {
      stop();
    }
  }, []);

  function stop() {
    codeReader.stopContinuousDecode();
    codeReader.stopStreams();
  }

  function decodeContinuously(deviceId) {
    codeReader.decodeFromVideoDevice(deviceId, 'video', (result, err) => {
      if (result) {
        console.log("Found barcode!", result);

        setCode(result.text);
        stop();
        props.advanceScanStage(result.text);
      }

      if (err) {
        setCode('');

        if (err instanceof NotFoundException) {
          console.log("No barcode found.");
        }

        if (err instanceof ChecksumException) {
          console.log("A barcode was found, but it's read value was not valid.");
        }

        if (err instanceof FormatException) {
          console.log("A barcode was found, but it was in a invalid format.");
        }
      }
    });
  }

  return (
    <div>
      <div className="warehouse-scanner-container">
        <video id="video" className="warehouse-scanner" />
      </div>
    </div>
  );
}

export default Scanner;
