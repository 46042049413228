import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import Table from 'react-bootstrap/Table';
import Order from './Order';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import loading from './assets/loading.gif';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';

import './Announcements.css'
import 'font-awesome/css/font-awesome.min.css';
import "react-datepicker/dist/react-datepicker.css";

class Announcements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      announcements: [],
      orderNumber: '',
      message: '',
      expiresAt: getNextDay(),
      sendEmail: false,
    };
  }

  async fetchAnnouncements() {
    this.setState({ loading: true });

    const announcements = (await axios.get('/api/announcements?all=true')).data

    this.setState({ loading: false });

    return announcements;
  }

  getWarehouseOption(id) {
    return this.state.warehouseOptions.find(option => option.value === id);
  }

  async componentDidMount() {
    this.setState({ loading: true });

    this.setState({ announcements: await this.fetchAnnouncements() });
  }

  onSendEmail = () => {
    if (this.state.orderNumber) {
      this.setState({sendEmail: !this.state.sendEmail})
    }
  }

  maybeCreateAnnouncement = async () => {
    if (this.state.sendEmail) {
      this.setState({isModalOpen: true})
    } else {
      this.createAnnouncement();
    }
  }

  createAnnouncement = async (sendEmail = false) => {
    const announcements = (await axios.post('/api/announcements', {
      order_number: this.state.orderNumber,
      message: this.state.message,
      expires_at: this.state.expiresAt,
      send_email: sendEmail
    })).data

    this.setState({ announcements, isModalOpen: false });
  }

  deleteAnnouncement = async (id) => {
    const announcements = (await axios.delete(`/api/announcements/${id}`)).data

    this.setState({ announcements });
  }

  render() {
    return (
      <div className="announcements-container">
        <p>If no order number(s) are provided, a global announcement is created and will be seen on all customer portals with active orders. No emails will be sent.</p>
        <p>If order number(s) are provided, an announcement will be seen on each order provided. Emails can optionally by checking the Send Email box.</p>
        <p>Multiple orders can be provided, with one on each line.</p>
        <div className="announcements-bar">
          <InputGroup>
            <div className="announcements-field">
              <label className="announcements-field-label">Order # (Optional)</label>
              <FormControl
                as="textarea"
                className="announcements-field-order"
                onChange={(e) => this.setState({orderNumber: e.target.value})}
              />
            </div>
            <div className="announcements-field">
              <label className="announcements-field-label">Message</label>
              <FormControl
                as="textarea"
                className="announcements-field-message"
                onChange={(e) => this.setState({message: e.target.value})}
              />
            </div>
            <div className="announcements-field">
              <label className="announcements-field-label">Expiration Date</label>
              <DatePicker selected={this.state.expiresAt} onChange={(expiresAt) => this.setState({expiresAt})} className="announcements-date-picker"/>
            </div>
            <div className="announcements-field">
              <label className="announcements-field-label">Send Email?</label>
              <input type="checkbox" checked={this.state.sendEmail} onChange={this.onSendEmail} />
            </div>
          </InputGroup>

          <div className="announcements-buttons">
            <Button variant="success" className="announcements-field-button" onClick={this.maybeCreateAnnouncement}>+ Create Announcement</Button>
          </div>
        </div>

        { !this.state.loading &&
          <div className="vertical">
            <Table striped bordered hover className="announcements-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Order #</th>
                  <th>Message</th>
                  <th>Created At</th>
                  <th>Expires At</th>
                </tr>
              </thead>
              <tbody>
                { this.state.announcements.map((announcement, idx) => {
                  return (
                    <tr>
                      <td><Button variant="danger" onClick={() => this.deleteAnnouncement(announcement.id)}>Delete</Button></td>
                      <td>{announcement.order?.cust_ref}</td>
                      <td>{announcement.message}</td>
                      <td>{moment.utc(announcement.created_at).tz("America/New_York").format('MM/DD/YY H:mm')}</td>
                      <td>{moment.utc(announcement.expires_at).tz("America/New_York").format('MM/DD/YY H:mm')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        }

        { this.state.isModalOpen &&
          <div className="popup" id="popup-dialog">
            <div className="popup-content" id="content">
              <div className="popup-content-container" >
                Warning: You will be sending emails to {this.state.orderNumber.split('\n').length} order(s). Are you sure you wish to proceed?

                <div className="popup-button-group">
                  <Button variant="danger" onClick={() => this.setState({isModalOpen: false})}>No</Button>
                  <Button className="popup-button" variant="success" onClick={() => this.createAnnouncement(true)}>Yes</Button>
                </div>
              </div>
            </div>
          </div>
        }

        { this.state.loading &&
          <div className="loading-container">
            <div className="loading-title">Loading...</div>
            <img className="loading" src={loading} alt=""/>
          </div>
        }
      </div>
    );
  }
}

function getNextDay(date = new Date()) {
  const nextDay = new Date(date.getTime());
  nextDay.setDate(date.getDate() + 1);

  return nextDay;
}

export default Announcements;
