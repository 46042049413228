import React from 'react';

import './Warehouse.css'

class WarehouseFooter extends React.Component {
  render() {
    return (
      <div className="warehouse-footer">
        {this.props.title}
      </div>
    );
  }
}

export default WarehouseFooter;
