import React from 'react';
import { Fragment } from 'react';
import './Warehouse.css'
import Button from 'react-bootstrap/Button';
import WarehouseHeader from './WarehouseHeader';
import WarehouseFooter from './WarehouseFooter';
import WebcamContainer from './WebcamContainer';
import Scanner from './Scanner';
import axios from 'axios';
import moment from 'moment-timezone';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class Warehouse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      warehouse: window.location.pathname.substring(1),
      stage: 0,
      scannedStage: 0,
      screen: 0,
      orderNumber: '',
      name: '',
      finalBoms: [],
      carriers: [],
      bomIndex: 0,
      bomCount: 0,
      bomImg: null,
      showBomList: true,
      numPallets: 0,
      selectedCarrier: {},
      proNumber: '',
    }
  }

  async componentDidMount() {
    const carriers = (await axios.get('/api/carriers')).data

    this.setState({carriers})
  }

  async getSnapshotBeforeUpdate(prevProps, prevState) {
    if (!this.getCurrentScreenId() && STAGES[prevState.stage]?.screens[prevState.screen]?.id) {
      await axios.put(`/api/orders/${this.state.orderNumber}`, {
        stage_completed: this.state.stage,
        name: this.state.name,
        warehouse: this.state.warehouse,
        num_pallets: this.state.numPallets,
        carrier_id: this.state.selectedCarrier.id,
        pro_number: this.state.proNumber,
      });
    }
  }

  isBomVerified() {
    const indexValid = this.state.bomIndex < this.state.finalBoms.length;
    const bomVerified = this.state.finalBoms[this.state.bomIndex].bomVerified

    return !!(this.state.finalBoms.length && indexValid && bomVerified)
  }

  setQcItem = (idx) => {
    this.setState({
      showBomList: false,
      bomIndex: idx
    });
  }

  openModal = (selection) => {
    this.setState({
      warehouse: selection.warehouse,
      format: selection.format,
      endpoint: `/api/components/import_inventory_${selection.warehouse.toLowerCase()}`,
      isModalOpen: true,
    });
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  }

  onNameInput = (e) => {
    this.setState({
      name: e.target.value,
    });
  }

  verifyBom = (override = false) => {
    if ((this.state.bomCount == this.state.finalBoms[this.state.bomIndex].quantity) || override) {
      const boms = this.state.finalBoms
      const bom = boms[this.state.bomIndex]
      bom.bomVerified = true
      bom.bomError = false

      this.setState({ finalBoms: boms });
    } else {
      const boms = this.state.finalBoms
      const bom = boms[this.state.bomIndex]
      bom.bomVerified = false
      bom.bomError = true

      this.setState({ finalBoms: boms })
    }

    if (override) {
      const expectedQuantity = this.state.finalBoms[this.state.bomIndex].quantity;
      const inputtedQuantity = this.state.bomCount;

      axios.post('/api/warehouse_events', {
        event_type: 'QA_OVERRIDE',
        warehouse: this.state.warehouse,
        name: this.state.name,
        order_number: this.state.orderNumber,
        message: `Expected ${expectedQuantity} of ${this.state.finalBoms[this.state.bomIndex].sku}, overridden with ${inputtedQuantity}.`,
      });
    }
  }

  onCustomOrder = () => {
    this.setState({ customOrderInput: false})

    this.advanceScanStage(this.state.orderNumber)
  }

  advanceScanStage = async (orderNumber) => {
    orderNumber = orderNumber.replace('#', '');

    const order = (await axios.get(`/api/orders/${orderNumber}`)).data;

    if (order) {
      this.setState({
        orderNumber,
        screen: this.state.screen + 1,
        scannedStage: order.warehouse_status.step,
        finalBoms: order.final_boms || [],
        order: order
      });
    } else {
      this.setState({
        orderNumber,
        screen: this.state.screen + 1,
        order: null
      })
    }
  }

  saveImage = async (image, hasMore) => {
    axios.post('/api/drive_images/upload_photo_screen', {
      orderNumber: this.state.orderNumber,
      stage: this.state.stage,
      warehouse: this.state.warehouse,
      name: this.state.name,
      image
    });

    if (!hasMore) {
      this.setState({
        screen: this.state.screen + 1
      });
    }
  }

  saveQaImage = async (image) => {
    axios.post('/api/drive_images/upload', {
      orderNumber: this.state.orderNumber,
      sku: this.state.finalBoms[this.state.bomIndex].sku,
      warehouse: this.state.warehouse,
      name: this.state.name,
      image
    });

    if (this.state.finalBoms.every(bom => bom.bomVerified)) {
      this.setState({screen: this.state.screen + 1 });
    } else {
      const boms = this.state.finalBoms.sort((a,b) => a.bomVerified ? 1 : -1 );
      this.setState({showBomList: true, finalBoms: boms });
    }
  }

  prev = () => {
    // TODO: current wont work if using this method on first screen of a stage
    this.setState({
      screen: this.state.screen - 1
    });
  }

  next = () => {
    this.setState({
      screen: this.state.screen + 1
    });
  }

  onStageSelection = (idx) => {
    this.restart();
    this.setState({
      stage: idx + 2
    });
  }

  restart = () => {
    this.setState({
      screen: 0,
      scannedStage: 0,
      orderNumber: '',
      finalBoms: [],
      bomIndex: 0,
      bomCount: 0,
      bomImg: null,
    });
  }

  selectStage = () => {
    this.setState({
      stage: 1,
      screen: 0,
    });
  }

  changeName = () => {
    this.setState({
      stage: 0,
      screen: 0,
    });
  }

  onPickItemToggle = (idx) => {
    const boms = this.state.finalBoms
    const bom = boms[idx]
    bom.pickCompleted = !bom.pickCompleted ? true : false
    boms[idx] = bom

    this.setState({
      finalBoms: boms
    });
  }

  // TODO: why doesn't s3 presigned links work?
  exportFile = async (selection) => {
    const resp = await axios.get(`/api/components/export_inventory_${selection.warehouse.toLowerCase()}`, { responseType: 'arraybuffer' });

    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `inventory_${selection.warehouse.toLowerCase()}.${selection.format}`);
    document.body.appendChild(link);
    link.click();
  }

  getScreens() {
    return STAGES[this.state.stage].screens;
  }

  getCurrentScreen() {
    return STAGES[this.state.stage]?.screens[this.state.screen]
  }

  getCurrentScreenId() {
    return STAGES[this.state.stage]?.screens[this.state.screen]?.id
  }

  render() {
    return (
      <div className="warehouse-container">

        { this.getCurrentScreenId() == 'SIGN_IN-DEFAULT' &&
          <div className="warehouse-stage">
            <div className="warehouse-title">Please enter your name:</div>
            <input type="text" className="form-control warehouse-input-name" onChange={(e) => this.onNameInput(e)}/>
            <Button variant="primary" className="warehouse-button warehouse-sm-button space-top" onClick={() => this.setState({stage: 1, screen: 0})}>Sign In</Button>
          </div>
        }

        { this.getCurrentScreenId() == 'CHOOSE-DEFAULT' &&
          <div className="warehouse-stage">
            <div className="warehouse-title">Choose Stage:</div>

            { [...STAGES.slice(2, STAGES.length)].map((stage, idx) => {
              return (
                <div key={idx} className="warehouse-stage-item" onClick={(e) => this.onStageSelection(idx)}>
                  <div className="warehouse-stage-item-number">{idx + 1}</div>
                  <div className="warehouse-stage-item-label">{stage.headerName}</div>
                </div>
              );
            })}
          </div>
        }

        { this.getCurrentScreenId() == 'BARCODE' &&
          <div className="warehouse-stage">
            <WarehouseHeader stage={this.state.stage - 1} name={this.state.name} stageName={STAGES[this.state.stage].headerName} selectStage={this.selectStage} changeName={this.changeName}/>

            <div className="center vertical">
              <Scanner advanceScanStage={(orderNumber) => this.advanceScanStage(orderNumber)}/>

              { !this.state.customOrderInput &&
                <div className="flex justify-center">
                  <Button className="warehouse-med-button" variant="primary" onClick={(e) => this.setState({ customOrderInput: true})}>Manual Input</Button>
                </div>
              }

              { this.state.customOrderInput &&
                <div className="flex flex-col justify-center items-center">
                  <input type="text" pattern="[0-9]*" className="warehouse-bom-input warehouse-bom-order-input form-control" onChange={(e) => this.setState({ orderNumber: e.target.value})}/>
                  <Button className="warehouse-sm-button mt-2" variant="primary" onClick={this.onCustomOrder}>Enter</Button>
                </div>
              }
            </div>

            <WarehouseFooter title={STAGES[this.state.stage].screens[this.state.screen].description}/>
          </div>
        }

        { this.getCurrentScreenId() == 'BARCODE_CONFIRM' &&
          <div className="warehouse-stage">
            <WarehouseHeader stage={this.state.stage - 1} name={this.state.name} stageName={STAGES[this.state.stage].headerName} selectStage={this.selectStage} changeName={this.changeName}/>

            <div className="warehouse-order-number">Order #{this.state.orderNumber} scanned!</div>

            { this.state.order && this.state.scannedStage === this.state.stage - 1 &&
              <div className="warehouse-button-container">
                <Button className="warehouse-sm-button" variant="primary" onClick={this.prev}>Rescan</Button>
                <Button className="warehouse-sm-button space-left" variant="success" onClick={this.next}>Next</Button>
              </div>
            }

            { this.state.order && this.state.scannedStage !== this.state.stage - 1 &&
              <div className="center">
                <div className="flex flex-col justify-center">
                  { this.state.scannedStage === 1 &&
                    <div className="warehouse-warning">
                      This order is on Stage 1. Are you sure you wish to proceed?
                    </div>
                  }

                  { this.state.scannedStage !== 1 &&
                    <div className="warehouse-warning">
                      This order last completed Stage {this.state.scannedStage - 1} on {moment.utc(this.state.order.updated_at).tz("America/New_York").format('MM/DD/YY H:mm')}. Are you sure you wish to proceed?
                    </div>
                  }

                  <div className="center mt-5">
                    <Button className="warehouse-sm-button" variant="primary" onClick={this.prev}>Rescan</Button>
                    <Button className="warehouse-sm-button space-left" variant="warning" onClick={this.next}>Override</Button>
                  </div>
                </div>
              </div>
            }

            { !this.state.order &&
              <div className="center">
                <div className="vertical">
                  <div className="warehouse-warning">
                    Order not found, please rescan.
                  </div>
                  <div className="center">
                    <Button className="warehouse-sm-button" variant="primary" onClick={this.prev}>Rescan</Button>
                  </div>
                </div>
              </div>
            }

            <WarehouseFooter title={STAGES[this.state.stage].screens[this.state.screen].description}/>
          </div>
        }

        { this.getCurrentScreenId() == 'TEXT_INPUT' &&
          <div className="warehouse-stage">
            <WarehouseHeader stage={this.state.stage - 1} name={this.state.name} stageName={STAGES[this.state.stage].headerName} selectStage={this.selectStage} changeName={this.changeName}/>

            { STAGES[this.state.stage].screens[this.state.screen].description == 'Enter Number of Pallets' &&
              <input type="text" pattern="[0-9]*" className="warehouse-bom-input form-control" onChange={(e) => this.setState({ numPallets: parseInt(e.target.value) })}/>
            }

            { STAGES[this.state.stage].screens[this.state.screen].description == 'Enter PRO Number' &&
              <input type="text" className="form-control warehouse-input-name" onChange={(e) => this.setState({ proNumber: e.target.value })}/>
            }

            <div className="flex mt-5 justify-center">
              <Button variant="primary" className="warehouse-button warehouse-xs-button" onClick={this.prev}>Back</Button>
              <Button variant="primary" className="warehouse-button warehouse-xs-button ml-2" onClick={this.next}>Next</Button>
            </div>

            <WarehouseFooter title={STAGES[this.state.stage].screens[this.state.screen].description}/>
          </div>
        }

        { this.getCurrentScreenId() == 'SELECT_INPUT' &&
          <div className="warehouse-stage">
            <WarehouseHeader stage={this.state.stage - 1} name={this.state.name} stageName={STAGES[this.state.stage].headerName} selectStage={this.selectStage} changeName={this.changeName}/>

            { STAGES[this.state.stage].screens[this.state.screen].description == 'Select Carrier' &&
              <DropdownButton
                as={ButtonGroup}
                size="sm"
                variant="primary"
                title={this.state.selectedCarrier?.name || 'Select'}
              >
                { this.state.carriers.map((carrier, idx) => {
                  return (
                    <Dropdown.Item key={carrier.name} onClick={(carrier) => this.setState({selectedCarrier: this.state.carriers[idx]})}>{carrier.name}</Dropdown.Item>
                  );
                })}
              </DropdownButton>
            }

            <div className="flex mt-5 justify-center">
              <Button variant="primary" className="warehouse-button warehouse-xs-button" onClick={this.prev}>Back</Button>
              <Button variant="primary" className="warehouse-button warehouse-xs-button ml-2" onClick={this.next}>Next</Button>
            </div>

            <WarehouseFooter title={STAGES[this.state.stage].screens[this.state.screen].description}/>
          </div>
        }

        { this.getCurrentScreenId() == 'PHOTO' &&
          <div className="warehouse-stage">
            <WarehouseHeader stage={this.state.stage - 1} name={this.state.name} stageName={STAGES[this.state.stage].headerName} selectStage={this.selectStage} changeName={this.changeName}/>

            <WebcamContainer multiple={this.state.stage == 4 || this.state.stage == 6} saveImage={(image, hasMore) => this.saveImage(image, hasMore)}/>

            <WarehouseFooter title={STAGES[this.state.stage].screens[this.state.screen].description}/>
          </div>
        }

        { this.getCurrentScreenId() == 'PICK' &&
          <div className="warehouse-stage">
            <div className="flex mb-3 justify-center">
              <Button variant="primary" className="warehouse-button warehouse-xs-button" onClick={this.prev}>Back</Button>
              <Button variant="primary" className="warehouse-button warehouse-xs-button ml-2" onClick={this.next}>Next</Button>
            </div>
            {
              this.state.finalBoms.map((bom, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => this.onPickItemToggle(idx)}
                    className={classNames(
                      bom.pickCompleted ? 'warehouse-stage-item-verified' : '',
                      'warehouse-stage-item warehouse-stage-img-item'
                    )}
                  >
                    <div className="warehouse-pick-item">
                      <div className="warehouse-stage-item-img-label warehouse-stage-pick-label"><b>{bom.sku}</b></div>
                      <img height="40" width="40" src={`https://ezice.s3.amazonaws.com/warehouse-app/${bom.sku}.jpg`}/>
                    </div>

                    { this.state.warehouse === '3pl' &&
                      <div className="flex">
                        <div className="warehouse-stage-item-location">
                          Isle: <b>EZ</b>
                        </div>
                        <div className="warehouse-stage-item-location">
                          Rack: <b>01</b>
                        </div>
                        <div className="warehouse-stage-item-location">
                          Shelf: <b>A2</b>
                        </div>
                        <div className="warehouse-stage-item-location">
                          Bin: <b>01</b>
                        </div>
                      </div>
                    }
                  </div>
                );
              })
            }
          </div>
        }

        { this.getCurrentScreenId() == 'COUNT' &&
          <div className="warehouse-stage">
            <WarehouseHeader stage={this.state.stage - 1} name={this.state.name} stageName={STAGES[this.state.stage]?.headerName} selectStage={this.selectStage} changeName={this.changeName}/>

            { this.state.showBomList ?
               this.state.finalBoms.map((bom, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => this.setQcItem(idx)}
                    className={classNames(
                      bom.bomVerified ? 'warehouse-stage-item-verified' : '',
                      'warehouse-stage-item warehouse-stage-img-item'
                    )}
                  >
                    <div className="warehouse-stage-item-img-label"><b>{bom.sku}</b></div>
                    <img height="40" width="40" src={`https://ezice.s3.amazonaws.com/warehouse-app/${bom.sku}.jpg`}/>
                  </div>
                );
              })
               :
              (
                <div>
                  { !this.isBomVerified() &&
                    <div className="warehouse-bom-container">
                      <div className="warehouse-bom-item">
                        <img className="warehouse-bom-img" src={`https://ezice.s3.amazonaws.com/warehouse-app/${this.state.finalBoms[this.state.bomIndex]?.sku}.jpg`} />
                        <input type="text" pattern="[0-9]*" className="warehouse-bom-input form-control" onChange={(e) => this.setState({ bomCount: parseInt(e.target.value)})}/>

                        { this.state.finalBoms[this.state.bomIndex].bomError ?
                          <div className="warehouse-bom-button-container">
                            <div className="warehouse-error">Incorrect Amount!</div>
                            <div className="flex mt-5 justify-center">
                              <Button variant="primary" className="warehouse-button" onClick={(e) => this.setState({ showBomList: true })}>Back</Button>
                              <Button variant="primary" className="warehouse-button space-left" onClick={(e) => this.verifyBom()}>Retry</Button>
                              <Button variant="primary" className="warehouse-button space-left" onClick={(e) => this.verifyBom(true)}>Override</Button>
                            </div>
                          </div>
                            :
                          <div className="flex mt-5 justify-center">
                            <Button variant="primary" className="warehouse-button warehouse-xs-button" onClick={(e) => this.setState({ showBomList: true })}>Back</Button>
                            <Button variant="primary" className="warehouse-button warehouse-xs-button ml-2" onClick={(e) => this.verifyBom()}>Next</Button>
                          </div>
                        }
                      </div>

                      <WarehouseFooter title={`Order #${this.state.orderNumber}: How many ${this.state.finalBoms[this.state.bomIndex]?.sku}?`}/>
                    </div>
                  }

                  { this.isBomVerified() &&
                    <div className="warehouse-bom-container">

                      <div className="warehouse-bom-item">
                        <WebcamContainer multiple={false} saveImage={(image) => this.saveQaImage(image)}/>
                      </div>

                      <WarehouseFooter title={`Please take a photo of ${this.state.finalBoms[this.state.bomIndex]?.sku}`}/>
                    </div>
                  }
                </div>
              )
            }
          </div>
        }

        { !this.getCurrentScreenId() &&
          <div className="warehouse-stage">
            <WarehouseHeader stage={this.state.stage - 1} name={this.state.name} stageName={STAGES[this.state.stage]?.headerName} selectStage={this.selectStage} changeName={this.changeName}/>

            <div className="warehouse-bom-done">
              <div className="warehouse-bom-done-text">Order #{this.state.orderNumber} {STAGES[this.state.stage].headerName} Complete!</div>
              <Button variant="primary" className="warehouse-button" onClick={this.restart}>Next Order</Button>
            </div>
          </div>
        }

      </div>
    );
  }
}

const STAGES = [
  { // 0
    id: 'SIGN_IN',
    screens: [
      {
        id: 'SIGN_IN-DEFAULT'
      }
    ],
  },
  { // 1
    id: 'CHOOSE',
    screens: [
      {
        id: 'CHOOSE-DEFAULT'
      }
    ],
  },
  { // 2
    id: 'PRINT',
    headerName: 'Print',
    screens: [
      {
        id: 'BARCODE',
        description: 'Scan Each Order'
      },
      {
        id: 'BARCODE_CONFIRM'
      }
    ],
  },
  { // 3
    id: 'PICK_BEGIN',
    headerName: 'Begin Pick',
    screens: [
      {
        id: 'BARCODE',
        description: 'Scan Order Bar Code'
      },
      {
        id: 'BARCODE_CONFIRM'
      },
      {
        id: 'PICK'
      }
    ],
  },
  { // 4
    id: 'PICK_COMPLETE',
    headerName: 'Pick Completed',
    screens: [
      {
        id: 'BARCODE',
        description: 'Scan Order Bar Code'
      },
      {
        id: 'BARCODE_CONFIRM'
      },
      {
        id: 'PHOTO',
        description: 'Take Photo of Pallet'
      },
    ],
  },
  { // 5
    id: 'QC',
    headerName: 'QC - Double Count',
    screens: [
      {
        id: 'BARCODE',
        description: 'Scan Order Bar Code'
      },
      {
        id: 'BARCODE_CONFIRM'
      },
      {
        id: 'COUNT',
      },
    ],
  },
  { // 6
    id: 'SHIP_READY',
    headerName: 'Ready to Ship',
    screens: [
      {
        id: 'BARCODE',
        description: 'Scan Order Bar Code'
      },
      {
        id: 'BARCODE_CONFIRM'
      },
      {
        id: 'PHOTO',
        description: 'Take Photo of Pallet'
      },
      {
        id: 'TEXT_INPUT',
        description: 'Enter Number of Pallets'
      },
      {
        id: 'SELECT_INPUT',
        description: 'Select Carrier'
      },
      {
        id: 'TEXT_INPUT',
        description: 'Enter PRO Number'
      },
    ],
  },
  { // 7
    id: 'SHIPPED',
    headerName: 'Shipped',
    screens: [
      {
        id: 'BARCODE',
        description: 'Scan Order Bar Code'
      },
      {
        id: 'BARCODE_CONFIRM'
      },
      {
        id: 'PHOTO',
        description: 'Take Photo of PRO# Bar Code'
      },
    ],
  },
]

export default Warehouse;
