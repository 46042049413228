import React from 'react';

import './Upgrades.css'
import Button from 'react-bootstrap/Button';
import axios from 'axios';

class Upgrades extends React.Component {
  // TODO: why doesn't s3 presigned links work?
  exportFile = async () => {
    const resp = await axios.get('/api/components/export_web_upgrade_components', { responseType: 'arraybuffer' });

    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `upgrades_${(new Date).toISOString()}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  render() {
    return (
      <div className="upgrades-container">
        <div className="upgrades-title">Web Upgrades</div>

        <div className="upgrades-section">
          <Button className="upgrades-button" variant="info" onClick={this.exportFile}>Export</Button>
        </div>
      </div>
    );
  }
}

export default Upgrades;
